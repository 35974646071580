import { FC } from "react";
import { Link } from "react-router-dom";
import { ROOT_DEFAULT_ROUTE } from "../../external-api-dashboard.page";
import { LogoUI } from "../../../../../domain/brand/logo.ui";
import { RiCopperCoinFill } from "react-icons/ri";
import { useCreditsDomain } from "../../domain/credits/credits.domain";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import useSWR from "swr";
import { CredentialsPanelWidget } from "./credentials-panel.widget";
import { LoadingAnimationUI } from "src/app/ui/animations/loading.animation.ui";

export const HeaderApiWidget: FC = () => {
  const { getCredits } = useCreditsDomain();
  const { sendRequest } = useRequestFeature();

  const { data: credits, isLoading: isGetCreditsLoading } = useSWR(
    "credits",
    () =>
      sendRequest({
        request: getCredits(),
      }),
  );

  return (
    <div
      className="animSlideTopIn flex items-center gap-4 border-b border-gray-200 bg-white"
      style={{ padding: "1.5rem 2rem" }}
    >
      <Link to={ROOT_DEFAULT_ROUTE}>
        <LogoUI style={{ height: "34px" }} />
      </Link>

      <div className="flex-1" />
      <div className="flex items-center gap-1">
        <RiCopperCoinFill />
        Credits: {isGetCreditsLoading ? <LoadingAnimationUI /> : credits}
      </div>
      <CredentialsPanelWidget />
    </div>
  );
};
