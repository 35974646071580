import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import {
  AuthenticationInput,
  ForgetPasswordInput,
  User,
  UserListElement,
  UserProfileInput,
  UserWithPermissions,
  enumBusinessStatus,
} from "src/graphql/client";

export interface UsersRepository {
  // createUser(user: ExternalApiSignUpInput, code: string): Promise<User>;
  login(loginProps: AuthenticationInput): Promise<{ sessionToken: string }>;
  logout(): Promise<boolean>;
  getLoggedInUser(): Promise<UserWithPermissions>;
  updateUser(input: UserProfileInput): Promise<User>;
  getUserByBrand(brandId: string): Promise<UserListElement[]>;
  getUsersByBusinessAccount(): Promise<UserListElement[]>;
  deleteUser(userId: string): Promise<boolean>;
  regeneratePassword(input: ForgetPasswordInput): Promise<boolean>;
}

export const useUsersDomain = (repoId = "UsersRepository") => {
  const { repository } = useRepositoryFeature<UsersRepository>(repoId);
  const { user } = useSessionFeature();

  const getUser = () => {
    return repository.getLoggedInUser();
  };

  const getUserByBrand = (brandId: string) => {
    return repository.getUserByBrand(brandId);
  };

  const updateUser = (input: UserProfileInput) => {
    return repository.updateUser(input);
  };

  const getUsersByBusinessAccount = () => {
    return repository.getUsersByBusinessAccount();
  };

  const deleteUser = (userId: string) => {
    return repository.deleteUser(userId);
  };

  const regeneratePassword = (input: ForgetPasswordInput) => {
    return repository.regeneratePassword(input);
  };

  const isUserReady = () => {
    return user?.businessAccount?.status === enumBusinessStatus.businessActive;
  };

  return {
    getUser,
    getUserByBrand,
    deleteUser,
    updateUser,
    getUsersByBusinessAccount,
    regeneratePassword,
    isUserReady,
  };
};
