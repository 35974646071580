import { token } from "src/app/legacy/token.tool";
import { CopilotChatRepository } from "src/domain/copilot-chat/copilot-chat.domain";
import {
  CopilotChatMessage,
  CopilotChatMessageType,
} from "src/domain/copilot-chat/message-copilot-chat.model";

export class CopilotChatBackendRepository implements CopilotChatRepository {
  async sendMessage(
    message: CopilotChatMessage,
    history: CopilotChatMessage[],
  ): Promise<CopilotChatMessage> {
    const endpointUrl = this.getEndpointUrl(message);

    history.shift();

    const response = await fetch(endpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token.asHeader()}`,
      },
      body: JSON.stringify([...history, message]),
    });

    if (!response.ok) {
      throw new Error("MemChat server is down");
    }

    const data: CopilotChatMessage = await response.json();
    return data;
  }

  private getEndpointUrl(message: CopilotChatMessage): string {
    const BASE_URL = "https://chat-dev.memorable.io";
    if (message.type === CopilotChatMessageType.Image) {
      return `${BASE_URL}/image`;
    }
    return `${BASE_URL}/text`;
  }
}
