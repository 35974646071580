import { FC } from "react";
import CardUI from "./card.ui";

const CardPageUI: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      className={`animFadeIn ${className}`}
      style={{ padding: "1rem 1.8rem" }}
    >
      <CardUI>
        <div style={{ padding: "1rem" }}>{children}</div>
      </CardUI>
    </div>
  );
};
export default CardPageUI;
