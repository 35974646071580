import { delayTool } from "src/app/tools/utils/delay.tool";
import { IntegrationRepository } from "src/domain/integrations/integration.domain";
import {
  IntegrationsAssets,
  Platform,
  PlatformState,
  enumPlatform,
} from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class IntegrationBackendRepository implements IntegrationRepository {
  private redirectUri = `${window.origin}/oauth/meta`;

  async createFacebookLoginUrl() {
    await delayTool(400);
    return `https://www.facebook.com/v16.0/dialog/oauth?client_id=5365968910151407&redirect_uri=${this.redirectUri}&scope=ads_read%2Cread_insights%2Cinstagram_basic%2Cinstagram_manage_insights%2Cpublic_profile%2Cpages_show_list%2Cpages_read_engagement&state=memorable`;
  }

  async disconnect(platform: Platform) {
    return client.chain.mutation
      .deletePlatformIntegration({
        platform,
      })
      .get();
  }

  async createFacebookIntegration(authCode: string) {
    return client.chain.mutation
      .createPlatformIntegration({
        authCode,
        platform: enumPlatform.META,
        redirectUri: this.redirectUri,
      })
      .get({
        adAccounts: {
          id: true,
          name: true,
        },
        socialAccounts: {
          id: true,
          name: true,
        },
      }) as Promise<IntegrationsAssets>;
  }

  async getPlatformIntegrated() {
    return client.chain.query.getPlatformConnections.get({
      createdAt: true,
      platform: true,
      updatedAt: true,
      expirationDate: true,
      extractedAds: true,
      extractedCreatives: true,
      status: true,
      totalAds: true,
      totalCreatives: true,
      userEmail: true,
    }) as Promise<PlatformState[]>;
  }
}
