import classNames from "classnames";
import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Route } from "src/app/features/navigation/models/route.model";
import { ApiOutlined, LinkOutlined } from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ExternalApiDashboardRoutes } from "../../external-api-dashboard.routes";
import MenuUI from "./menu.ui";

export const NavbarApiWidget: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const onlyStaticRoutes = (route: Route) => {
    if (route.path.includes("http")) return true;
    return !route.path.includes(":");
  };

  const isPathAUrl = (path: string) => {
    return path.includes("http");
  };

  const itemMapper = (route: Route) => {
    return {
      key: route.path,
      icon: isPathAUrl(route.path) ? <LinkOutlined /> : undefined,
      label: route.title,
    };
  };

  const items: ItemType[] = [
    {
      key: "2",
      icon: <ApiOutlined />,
      children:
        ExternalApiDashboardRoutes.filter(onlyStaticRoutes).map(itemMapper),
      label: "Memorable API",
    },
  ];

  const getAllItemKeys = (items: ItemType[]): string[] => {
    return items.map((i) => String(i?.key));
  };

  const itemSelectHandler = (path: string) => {
    if (isPathAUrl(path)) {
      window.open(path, "_blank");
      return;
    }
    navigate(path);
  };

  return (
    <div
      style={{ padding: "0rem", display: "flex", flexDirection: "column" }}
      className={classNames("animSlideLeftIn", "transition-all duration-500")}
    >
      <MenuUI
        onSelect={itemSelectHandler}
        defaultSelectedKeys={["2", location.pathname]}
        defaultOpenKeys={getAllItemKeys(items)}
        items={items}
      />
    </div>
  );
};
