import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { Prediction } from "src/graphql/client";

export interface CreditsRepository {
  getCredits(): Promise<number>;
  getApiKeys(): Promise<any>;
  createApiKey(keyName: string): Promise<any>;
  deleteApiKey(appKeyId: number): Promise<any>;
  getPredictions(): Promise<Prediction[]>;
}

export const useCreditsDomain = (repoId = "CreditsRepository") => {
  const { repository } = useRepositoryFeature<CreditsRepository>(repoId);

  const getCredits = () => {
    return repository.getCredits();
  };

  const getApiKeys = () => {
    return repository.getApiKeys();
  };

  const createApiKey = (keyName: string) => {
    return repository.createApiKey(keyName);
  };

  const deleteApiKey = (appKeyId: number) => {
    return repository.deleteApiKey(appKeyId);
  };

  const getPredictions = () => {
    return repository.getPredictions();
  };

  return {
    getCredits,
    getApiKeys,
    createApiKey,
    deleteApiKey,
    getPredictions,
  };
};
