import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { env } from "src/app/legacy/env.tool";
import { token } from "src/app/legacy/token.tool";
import { useSessionState } from "./session.state";
import * as Sentry from "@sentry/react";
import {
  AuthenticationInput,
  Brand,
  UserWithPermissions,
} from "src/graphql/client";
import { UsersRepository } from "src/domain/users/users.domain";
import { useRequestFeature } from "../requests/request.feature";

export const useSessionFeature = (repoId = "UsersRepository") => {
  const { repository } = useRepositoryFeature<UsersRepository>(repoId);

  const { sendRequest } = useRequestFeature();

  const state = useSessionState();

  const selectBrand = (brand: Brand) => {
    if (!brand) return;
    state.selectBrand(brand);
  };

  const logout = async () => {
    if (env("VITE_APP_SENTRY_DSN")) {
      Sentry.setUser(null);
    }

    return sendRequest({
      request: repository.logout(),
      id: "logout",
      onSuccess(response) {
        localStorage.clear();
        token.clear();
        state.removeUser();
        return response;
      },
    });
  };

  const login = (loginProps: AuthenticationInput) => {
    return new Promise((resolve, reject) => {
      repository
        .login(loginProps)
        .then(({ sessionToken }) => {
          token.persist = true;
          token.set(sessionToken);
          setSessionId(sessionToken);
          repository
            .getLoggedInUser()
            .then((user) => {
              state.setUser(user);
              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getUser = () => {
    return repository.getLoggedInUser();
  };

  const setUser = (user: UserWithPermissions | undefined) => {
    state.setUser(user);
  };

  const isAdmin = () => {
    return state.user?.isAdmin;
  };

  const setSessionId = (sessionId: string) => {
    state.setSessionId(sessionId);
  };

  return {
    user: state.user as UserWithPermissions,
    currentBrand: state.currentBrand,
    selectBrand,
    login,
    logout,
    getUser,
    setSessionId,
    setUser,
    isAdmin,
  };
};
