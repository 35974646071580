import { SectorsBackendRepository } from "./sectors.repository";

import { AssetsBackendRepository } from "./assets.repository";
import { BusinessBackendRepository } from "./business.repository";
import { EarlyAccessBackendRepository } from "./early-access.repository";
import { IntegrationBackendRepository } from "./integration.repository";
import { PretestBackendRepository } from "./pretest.repository";
import { UsersBackendRepository } from "./users.repository";

import { CopilotChatBackendRepository } from "./copilot-chat.repository";
import { CreditsBackendRepository } from "./credits.repository";
import { PermissionsBackendRepository } from "./permissions.repository";
import { TagsBackendRepository } from "./tags.repository";

export const repository = new Map();

repository.set("SectorsRepository", SectorsBackendRepository);
repository.set("IntegrationsRepository", IntegrationBackendRepository);
repository.set("PermissionsRepository", PermissionsBackendRepository);
repository.set("UsersRepository", UsersBackendRepository);
repository.set("AssetsRepository", AssetsBackendRepository);
repository.set("TagsRepository", TagsBackendRepository);

repository.set("CopilotChatRepository", CopilotChatBackendRepository);
repository.set("CreditsRepository", CreditsBackendRepository);
repository.set("EarlyAccessRepository", EarlyAccessBackendRepository);
repository.set("BusinessRepository", BusinessBackendRepository);
repository.set("PretestRepository", PretestBackendRepository);
