import { useEffect } from "react";
import intl from "react-intl-universal";

import useForceUpdate from "use-force-update";

import enUS from "./locales/en-US.json";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";

const LOCALES_LIST = [
  {
    label: "English",
    value: "en-US",
  },
];

const LOCALE_DATA = {
  "en-US": enUS,
};

export const useTranslationFeature = () => {
  const forceUpdate = useForceUpdate();

  const defaultLocale = "en-US";

  const initializeIntl = () => {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang",
    });

    if (!LOCALES_LIST.some((item) => item.value === currentLocale)) {
      currentLocale = defaultLocale;
    }

    setCurrentLocale(currentLocale);
  };

  const setCurrentLocale = (currentLocale: string) => {
    intl.init({
      currentLocale,
      locales: LOCALE_DATA,
    });
  };

  const changeLang = (value: string) => {
    setCurrentLocale(value);
    forceUpdate();
  };

  const translateOrEmpty = (key: string, options?: any) => {
    return intl.get(key, options);
  };

  const t = (key: string, options?: any) => {
    return translateOrEmpty(key, options) || formatKeyAsValue(key);
  };

  const formatKeyAsValue = (key: string) => {
    const keyParts = key.split(".");
    let a = keyParts.at(-1);
    if (!a) a = key;
    return titleCaseFormatterTool(a);
  };

  useEffect(() => {
    initializeIntl();
  }, []);

  return {
    t,
    translateOrEmpty,
    changeLang,
  };
};
