import { CreditsRepository } from "src/app/pages/external-api-dashboard/domain/credits/credits.domain";
import { client } from "../clients/graphql.client";
import { Prediction } from "src/graphql/client";

export class CreditsBackendRepository implements CreditsRepository {
  getCredits() {
    return client.chain.query.getCredits.get() as Promise<number>;
  }

  getApiKeys() {
    return client.chain.query.getApiKeys.get({
      id: 1,
      apiKeyName: 1,
      endsWith: 1,
    }) as Promise<any>;
  }

  createApiKey(apiKeyName: string) {
    return client.chain.mutation
      .createApiKey({
        apiKeyName,
      })
      .get() as Promise<any>;
  }

  deleteApiKey(apiKeyId: number) {
    return client.chain.mutation.deleteApiKey({ apiKeyId }).get();
  }

  getPredictions() {
    return client.chain.query.getRecentAPICalls.get({
      createdAt: 1,
      endpoint: 1,
      compromisedCredits: 1,
      finishedAt: 1,
      startedAt: 1,
      Id: 1,
      input: 1,
      status: 1,
    }) as Promise<Prediction[]>;
  }
}
