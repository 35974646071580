import { Route } from "src/app/features/navigation/models/route.model";
import { lazy } from "react";

const OverviewAPIPage = lazy(
  () => import("./pages/overview-api/overview-api.page"),
);
const BillingAPIPage = lazy(
  () => import("./pages/billing-api/billing-api.page"),
);

const CreditsAPIPage = lazy(
  () => import("./pages/credits-api/credits-api.page"),
);

const DocsAPIPage = lazy(() => import("./pages/docs-api/docs-api.page"));

const APIkeysAPIPage = lazy(() => import("./pages/api-keys/api-keys.page"));

const ExploreMetricsAPIPage = lazy(
  () => import("./pages/explore-metrics-api/explore-metrics-api.page"),
);

export const ExternalApiDashboardRoutes: Route[] = [
  {
    path: "/",
    element: OverviewAPIPage,
    title: "Overview",
  },
  {
    path: "https://tekalai.notion.site/eabc3658ffee422c925b9a401140d36f?v=af35cd054cab46949b19d67d5abefe67&pvs=4",
    element: ExploreMetricsAPIPage,
    title: "Explore metrics",
  },
  {
    path: "/docs",
    element: DocsAPIPage,
    title: "Docs",
  },
  {
    path: "/api-keys",
    element: APIkeysAPIPage,
    title: "API keys",
  },
  {
    path: "/billing",
    element: BillingAPIPage,
    title: "Billing",
  },
  {
    path: "/credits",
    element: CreditsAPIPage,
    title: "Credits",
  },
];
