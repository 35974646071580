import { Route } from "src/app/features/navigation/models/route.model";
import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import {
  Invitation,
  InvitationInput,
  PermissionGroup,
  UserPermissionsInput,
} from "src/graphql/client";

export interface PermissionsRepository {
  getBusinessAccountPermissionGroup(): Promise<PermissionGroup[]>;
  updateUserPermissions(input: UserPermissionsInput): Promise<boolean>;
  createInternalInvitations(input: InvitationInput): Promise<Invitation[]>;
  removeInternalInvitations(code: string): Promise<boolean>;
  getActiveInternalInvitations(): Promise<Invitation[]>;
  resendInvitation(invitationCode: string): Promise<boolean>;
}

export const usePermissionsDomain = (repoId = "PermissionsRepository") => {
  const { repository } = useRepositoryFeature<PermissionsRepository>(repoId);

  const { user, isAdmin, currentBrand } = useSessionFeature();

  const canISeeBrandSettings = () => {
    if (isAdmin()) return false;
    return hasUserPermission(2);
  };

  const canINavigateTo = (route: Route) => {
    if (user === null) return true;
    const missingPermissions = route.requiredPermissions?.some((permission) => {
      return !hasUserPermission(permission);
    });
    if (missingPermissions) {
      return false;
    }
    if (route.isOnlyAdmin) {
      return user?.isAdmin;
    }
    if (route.isExcludeAdmin) {
      return !user?.isAdmin;
    }
    return true;
  };

  const hasUserPermission = (permissonKey: number) => {
    const a = user?.brandPermissions ?? [];
    if (!a) return false;
    const b = a.find((b) => b.brandId === currentBrand?.id);
    if (!b) return false;
    return b.permissions.indexOf(permissonKey) > -1;
  };

  const getBusinessAccountPermissionGroup = () => {
    return repository.getBusinessAccountPermissionGroup();
  };

  const updateUserPermissions = (input: UserPermissionsInput) => {
    return repository.updateUserPermissions(input);
  };

  const createInternalInvitations = (input: InvitationInput) => {
    return repository.createInternalInvitations(input);
  };

  const getActiveInternalInvitations = () => {
    return repository.getActiveInternalInvitations();
  };

  const removeInternalInvitations = (code: string) => {
    return repository.removeInternalInvitations(code);
  };

  const resendInvitation = (invitationCode: string) => {
    return repository.resendInvitation(invitationCode);
  };

  return {
    getBusinessAccountPermissionGroup,
    getActiveInternalInvitations,
    removeInternalInvitations,
    createInternalInvitations,
    resendInvitation,
    updateUserPermissions,
    canISeeBrandSettings,
    canINavigateTo,
  };
};
