export default {
  scalars: [
    3, 4, 5, 10, 12, 16, 19, 22, 23, 24, 28, 29, 32, 33, 37, 38, 40, 44, 45, 46,
    48, 52, 59, 60, 62, 63, 64, 66, 75, 78, 79, 81, 83, 93, 94, 139, 147, 161,
  ],
  types: {
    Query: {
      getAdRanking: [
        1,
        {
          filter: [13, "AdRankingInput!"],
        },
      ],
      getAdGroupRanking: [
        25,
        {
          filter: [13, "AdRankingInput!"],
        },
      ],
      getCampaignRanking: [
        26,
        {
          filter: [13, "AdRankingInput!"],
        },
      ],
      getBrand: [
        27,
        {
          brandId: [4, "String!"],
        },
      ],
      getBrandAssets: [
        35,
        {
          brandId: [4, "String!"],
        },
      ],
      getAvailablePacks: [4],
      getBusinessAccountPermissionGroup: [41],
      getBusinessAccount: [
        42,
        {
          id: [4, "String!"],
        },
      ],
      getPlatformConnections: [47],
      getBusinessAccountAssets: [49],
      getBusinessAccountBrands: [50],
      getAdProfile: [
        51,
        {
          filter: [65, "CreativeAnalyticFilter!"],
          adId: [4, "String!"],
        },
      ],
      getCreativeAnalyticsSummary: [
        68,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getInsights: [
        69,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getTagsByUsage: [
        70,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getHighlights: [
        71,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getHighlightsV2: [
        72,
        {
          filter: [73, "HighlightsInput!"],
        },
      ],
      getTagRanking: [
        76,
        {
          tagFilter: [20, "[TagFilterInput!]"],
          orderingColumn: [82],
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getTagSummary: [
        84,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getTagUsageRanking: [
        86,
        {
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getCreativePerformanceOverTime: [
        87,
        {
          metric: [4, "String!"],
          adId: [4, "String!"],
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getTagPerformanceOverTime: [
        89,
        {
          metric: [4, "String!"],
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getTagPerformanceOverTimeAllChannel: [
        89,
        {
          metric: [4, "String!"],
          filter: [65, "CreativeAnalyticFilter!"],
        },
      ],
      getChannelAvailableOptions: [
        91,
        {
          creativeIds: [4, "[String!]!"],
        },
      ],
      creative: [
        92,
        {
          creativeId: [4, "String!"],
        },
      ],
      folder: [
        99,
        {
          input: [101, "CreativeLibraryFilter!"],
        },
      ],
      performanceMetrics: [
        102,
        {
          filter: [104, "PerformanceMetricFiltersInput!"],
          brandId: [4, "String!"],
        },
      ],
      ctvBundles: [4],
      getRecentAPICalls: [105],
      getAPIMonthlySummary: [
        106,
        {
          input: [110, "APIDetailRequest!"],
        },
      ],
      validateInvitationCode: [
        4,
        {
          invitationCode: [4, "String!"],
        },
      ],
      getActiveInternalInvitations: [111],
      getCredits: [10],
      getBrandTags: [
        70,
        {
          brandId: [4, "String!"],
        },
      ],
      getTagsFromCreative: [
        57,
        {
          creativeId: [4, "String!"],
        },
      ],
      getLoggedInUser: [113],
      getUsersByBusinessAccount: [117],
      getUsersByBrand: [
        117,
        {
          brandId: [4, "String!"],
        },
      ],
      getApiKeys: [118],
      __typename: [4],
    },
    AdRankingElement: {
      ad: [2],
      channels: [5],
      adGroup: [6],
      campaign: [7],
      metrics: [8],
      __typename: [4],
    },
    Ad: {
      id: [3],
      name: [4],
      creativeId: [4],
      campaignObjective: [4],
      targetingCountries: [4],
      url: [4],
      thumbnailUrl: [4],
      __typename: [4],
    },
    ID: {},
    String: {},
    Channel: {},
    AdGroup: {
      id: [3],
      name: [4],
      targetingCountries: [4],
      __typename: [4],
    },
    Campaign: {
      id: [3],
      name: [4],
      objective: [4],
      targetingCountries: [4],
      __typename: [4],
    },
    RankingMetrics: {
      spendTotal: [9],
      impressionsTotal: [9],
      engagementRate: [11],
      costPerEngagement: [11],
      videoThroughRate: [11],
      clickThroughRate: [11],
      leadRate: [11],
      conversionRate: [11],
      appInstallRate: [11],
      costPerMille: [11],
      costPerClick: [11],
      costPerLead: [11],
      costPerAcquisition: [11],
      costPerAppInstall: [11],
      costPerCompletedView: [11],
      frequency: [11],
      brandLift: [11],
      __typename: [4],
    },
    TotalValue: {
      value: [10],
      total: [10],
      __typename: [4],
    },
    Float: {},
    MetricVariance: {
      metric: [12],
      variance: [10],
      value: [10],
      average: [10],
      __typename: [4],
    },
    Metric: {},
    AdRankingInput: {
      brandId: [3],
      adFilter: [14],
      metricFilter: [17],
      tagFilters: [20],
      order: [21],
      __typename: [4],
    },
    AdFilterInput: {
      campaignIds: [3],
      adGroupIds: [3],
      adTargeting: [15],
      textSearch: [4],
      __typename: [4],
    },
    AdTargeting: {
      country: [16],
      countries: [16],
      __typename: [4],
    },
    Country: {},
    MetricFilterInput: {
      timeRanges: [18],
      channels: [5],
      __typename: [4],
    },
    TimeRange: {
      init: [19],
      end: [19],
      __typename: [4],
    },
    DateTime: {},
    TagFilterInput: {
      tagSuperClass: [4],
      tagClass: [4],
      tagValue: [4],
      __typename: [4],
    },
    AdRankingOrderingInput: {
      orderingDirection: [22],
      pageNumber: [23],
      pageSize: [23],
      orderingColumn: [24],
      __typename: [4],
    },
    OrderingDirection: {},
    Int: {},
    AdRankingOrderingColumn: {},
    AdGroupRankingElement: {
      adGroup: [6],
      metrics: [8],
      channels: [5],
      campaign: [7],
      __typename: [4],
    },
    CampaignRankingElement: {
      campaign: [7],
      metrics: [8],
      channels: [5],
      __typename: [4],
    },
    Brand: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      name: [4],
      logoUrl: [4],
      sector: [28],
      country: [4],
      adAccounts: [4],
      socialAccounts: [4],
      status: [29],
      businessAccount: [30],
      createdBy: [4],
      extraAttributes: [34],
      countries: [16],
      __typename: [4],
    },
    Sector: {},
    BrandStatus: {},
    BusinessAccount: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      businessName: [4],
      users: [31],
      brands: [27],
      businessLogoUrl: [4],
      businessPhone: [4],
      website: [4],
      address: [4],
      status: [33],
      __typename: [4],
    },
    User: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      email: [4],
      name: [4],
      isAdmin: [32],
      businessAccount: [30],
      termsAndConditionsAccepted: [19],
      emailVerified: [32],
      isContractValid: [32],
      __typename: [4],
    },
    Boolean: {},
    BusinessStatus: {},
    BrandExtraAttribute: {
      names: [4],
      communicationIdeas: [4],
      visualElements: [4],
      overlayTextTaglines: [4],
      speechTaglines: [4],
      mustWinAttributes: [4],
      __typename: [4],
    },
    BrandAssetsResponse: {
      adAccounts: [36],
      socialAccounts: [39],
      __typename: [4],
    },
    AdAccount: {
      id: [3],
      originalId: [4],
      platform: [37],
      type: [38],
      name: [4],
      currency: [4],
      __typename: [4],
    },
    Platform: {},
    AdAccountType: {},
    SocialAccount: {
      id: [3],
      originalId: [4],
      platform: [37],
      type: [40],
      name: [4],
      __typename: [4],
    },
    SocialAccountType: {},
    PermissionGroup: {
      id: [23],
      name: [4],
      __typename: [4],
    },
    BusinessAccountOutput: {
      config: [43],
      __typename: [4],
    },
    BusinessAccountConfig: {
      pack: [4],
      allowedChannels: [5],
      purchasableChannels: [5],
      allowedCreativeAnalyticsChannels: [5],
      allowedCreativeLabChannels: [5],
      purchasableCreativeAnalyticsChannels: [5],
      purchasableCreativeLabChannels: [5],
      visibleRecommendationSources: [4],
      visibleFeatures: [44],
      visibleCognitiveMetrics: [45],
      visibleTagMetrics: [12],
      visibleAdMetrics: [12],
      visibleCreativeAnalyticsCampaignObjectives: [46],
      visibleCreativeLabCampaignObjectives: [46],
      __typename: [4],
    },
    Feature: {},
    CognitiveMetric: {},
    CampaignObjective: {},
    PlatformState: {
      platform: [37],
      status: [48],
      userEmail: [4],
      expirationDate: [19],
      createdAt: [19],
      updatedAt: [19],
      totalAds: [23],
      totalCreatives: [23],
      extractedAds: [23],
      extractedCreatives: [23],
      __typename: [4],
    },
    ConnectionState: {},
    IntegrationsAssets: {
      adAccounts: [36],
      socialAccounts: [39],
      __typename: [4],
    },
    BrandListElement: {
      id: [4],
      name: [4],
      logoUrl: [4],
      status: [29],
      country: [16],
      sector: [28],
      users: [23],
      __typename: [4],
    },
    AdProfile: {
      advertisementId: [4],
      creativeId: [4],
      brandId: [4],
      name: [4],
      fileType: [52],
      campaignObjective: [46],
      channel: [5],
      metrics: [53],
      creativeMetrics: [55],
      tags: [57],
      recommendations: [58],
      url: [4],
      heatmapUrl: [4],
      __typename: [4],
    },
    FileType: {},
    Metrics: {
      spend: [54],
      usage: [54],
      impressions: [54],
      reach: [54],
      engagement: [54],
      engagementRate: [54],
      costPerEngagement: [54],
      videoViews: [54],
      videoThroughRate: [54],
      costPerCompletedView: [54],
      clicks: [54],
      clickThroughRate: [54],
      costPerClick: [54],
      frequency: [54],
      costPerMille: [54],
      conversion: [54],
      conversionRate: [54],
      costPerAcquisition: [54],
      leads: [54],
      leadRate: [54],
      costPerLead: [54],
      appInstall: [54],
      appInstallRate: [54],
      costPerAppInstall: [54],
      brandLift: [54],
      brandLiftRatio: [54],
      costPerBrandLift: [54],
      __typename: [4],
    },
    MetricValue: {
      value: [10],
      __typename: [4],
    },
    CreativeMetrics: {
      memorability: [56],
      brandPresence: [56],
      distinctiveness: [56],
      aesthetics: [56],
      authenticity: [56],
      engagementPotential: [56],
      aestheticAIGenerated: [56],
      memorabilityTvc: [56],
      memorabilityOoh: [56],
      memorabilityLong: [56],
      memorabilitySm: [56],
      memorabilityDecay: [56],
      focus: [56],
      cognitiveLoad: [56],
      memorabilityRate: [56],
      brandLift: [56],
      cognitiveEase: [56],
      __typename: [4],
    },
    CreativeMetricValue: {
      value: [10],
      quantiles: [10],
      rawValue: [10],
      __typename: [4],
    },
    Tag: {
      tagSuperClass: [4],
      tagClass: [4],
      tagValue: [4],
      importance: [10],
      __typename: [4],
    },
    Recommendation: {
      ruleId: [23],
      goal: [4],
      source: [4],
      specificSource: [4],
      type: [59],
      campaignObjective: [46],
      action: [60],
      title: [4],
      customMessage: [4],
      targetTag: [57],
      targetTags: [57],
      sourceTag: [57],
      sourceTags: [57],
      metricImpacts: [61],
      channel: [5],
      impactedCognitive: [45],
      __typename: [4],
    },
    RecommendationType: {},
    Action: {},
    MetricImpact: {
      metric: [62],
      impact: [10],
      trend: [63],
      impactScore: [64],
      __typename: [4],
    },
    RecommendationMetric: {},
    Trend: {},
    ImpactScore: {},
    CreativeAnalyticFilter: {
      brandId: [3],
      range: [18],
      campaignObjective: [46],
      superClass: [66],
      tags: [67],
      channels: [5],
      creativeIds: [4],
      adTargeting: [15],
      __typename: [4],
    },
    SuperClass: {},
    TagInput: {
      tagSuperClass: [4],
      tagClass: [4],
      tagValue: [4],
      importance: [10],
      channel: [5],
      __typename: [4],
    },
    MetricsSummary: {
      creativesTotal: [23],
      adsTotal: [23],
      metrics: [53],
      __typename: [4],
    },
    Insight: {
      tag: [57],
      tags: [57],
      spend: [10],
      usage: [23],
      campaignObjective: [46],
      result: [11],
      costResult: [11],
      expectedImpact: [23],
      channel: [5],
      __typename: [4],
    },
    TreeNode: {
      key: [4],
      nestedTotal: [23],
      children: [70],
      __typename: [4],
    },
    BestAndWortsHighlight: {
      best: [72],
      worst: [72],
      __typename: [4],
    },
    Highlight: {
      advertisementId: [4],
      name: [4],
      fileType: [52],
      campaignObjective: [46],
      result: [11],
      channel: [5],
      url: [4],
      __typename: [4],
    },
    HighlightsInput: {
      brandId: [3],
      range: [18],
      campaignObjective: [46],
      tags: [67],
      channels: [5],
      creativeIds: [4],
      adTargeting: [15],
      order: [74],
      __typename: [4],
    },
    HighlightsOrderingInput: {
      orderingDirection: [22],
      pageNumber: [23],
      pageSize: [23],
      orderingColumn: [75],
      __typename: [4],
    },
    HighlightsOrderingColumn: {},
    TagOverallMetrics: {
      tag: [57],
      channels: [5],
      tip: [77],
      creativesTotal: [9],
      adsTotal: [9],
      spendTotal: [9],
      impressionsTotal: [9],
      engagementRate: [11],
      costPerEngagement: [11],
      videoThroughRate: [11],
      clickThroughRate: [11],
      leadRate: [11],
      conversionRate: [11],
      appInstallRate: [11],
      costPerMille: [11],
      costPerClick: [11],
      costPerLead: [11],
      costPerAcquisition: [11],
      costPerAppInstall: [11],
      costPerCompletedView: [11],
      frequency: [11],
      brandLiftRatio: [11],
      costPerBrandLift: [11],
      brandLift: [11],
      __typename: [4],
    },
    Tip: {
      value: [78],
      detail: [79],
      placeHolders: [80],
      __typename: [4],
    },
    TipValue: {},
    TipDetail: {},
    TipPlaceHolder: {
      value: [4],
      key: [4],
      type: [81],
      __typename: [4],
    },
    PlaceHolderType: {},
    TagRankingOrderingInput: {
      orderingDirection: [22],
      pageNumber: [23],
      pageSize: [23],
      orderingColumn: [83],
      __typename: [4],
    },
    TagRankingOrderingColumn: {},
    TagSummary: {
      tagUsage: [85],
      impressions: [85],
      tagSpend: [85],
      frequency: [85],
      costPerMile: [85],
      engagementRate: [85],
      costPerEngagement: [85],
      videoThroughRate: [85],
      costPerCompletedVideo: [85],
      clickThroughRate: [85],
      costPerClick: [85],
      leadRate: [85],
      costPerLead: [85],
      conversionRate: [85],
      costPerAcquisition: [85],
      appInstallRate: [85],
      costPerAppInstall: [85],
      brandLiftRatio: [85],
      costPerBrandLift: [85],
      brandLift: [85],
      __typename: [4],
    },
    MetricRange: {
      max: [10],
      min: [10],
      avg: [10],
      __typename: [4],
    },
    TagMetric: {
      tag: [57],
      channel: [5],
      metrics: [53],
      __typename: [4],
    },
    DataPerformanceEntry: {
      date: [4],
      data: [88],
      __typename: [4],
    },
    SerialData: {
      main_0: [10],
      metric_0: [10],
      main_1: [10],
      metric_1: [10],
      main_2: [10],
      metric_2: [10],
      main_3: [10],
      metric_3: [10],
      main_4: [10],
      __typename: [4],
    },
    PerformanceDataEntry: {
      date: [4],
      data: [90],
      __typename: [4],
    },
    SerialEntry: {
      key: [4],
      main: [10],
      metric: [10],
      __typename: [4],
    },
    ChannelOptions: {
      channel: [5],
      placements: [4],
      campaignObjectives: [46],
      __typename: [4],
    },
    Creative: {
      brandId: [4],
      createdAt: [19],
      fileType: [52],
      id: [4],
      name: [4],
      status: [93],
      type: [94],
      updatedAt: [19],
      url: [4],
      thumbnailUrl: [4],
      clipEmbeddingUrl: [4],
      heatmapUrl: [4],
      copies: [
        95,
        {
          filter: [96, "CopyInput!"],
        },
      ],
      recommendations: [
        58,
        {
          input: [97, "RecommendationsInput!"],
        },
      ],
      cognitiveScores: [55],
      cognitiveScoresByFrame: [98],
      tags: [57],
      __typename: [4],
    },
    CreativeStatus: {},
    CreativeType: {},
    Copy: {
      title: [4],
      description: [4],
      __typename: [4],
    },
    CopyInput: {
      channel: [5],
      objective: [46],
      gender: [4],
      minAge: [23],
      maxAge: [23],
      keyClaim: [4],
      metric: [4],
      language: [4],
      additionalContext: [4],
      __typename: [4],
    },
    RecommendationsInput: {
      claim: [4],
      types: [59],
      campaignObjective: [46],
      channels: [5],
      placement: [4],
      __typename: [4],
    },
    TimeSeriesMetrics: {
      type: [45],
      values: [10],
      __typename: [4],
    },
    CreativeLibraryFolder: {
      id: [10],
      parentId: [10],
      path: [4],
      createdAt: [19],
      updatedAt: [19],
      folders: [99],
      creatives: [
        92,
        {
          input: [100],
        },
      ],
      __typename: [4],
    },
    CreativeFilter: {
      creativeType: [94],
      __typename: [4],
    },
    CreativeLibraryFilter: {
      brandId: [4],
      folderId: [23],
      creativeType: [94],
      __typename: [4],
    },
    PerformanceMetricsV2: {
      engagementRate: [103],
      videoThroughRate: [103],
      clickThroughRate: [103],
      brandLift: [103],
      appInstallRate: [103],
      conversionRate: [103],
      attention: [103],
      positiveEmotion: [103],
      distraction: [103],
      confusion: [103],
      awareness: [103],
      action: [103],
      __typename: [4],
    },
    PerformanceMetricValue: {
      value: [10],
      quantiles: [10],
      rawValue: [10],
      affectingCognitives: [45],
      recommendations: [58],
      __typename: [4],
    },
    PerformanceMetricFiltersInput: {
      creativeId: [4],
      targetingAgeMax: [23],
      targetingAgeMin: [23],
      campaignObjective: [46],
      channel: [5],
      placement: [4],
      country: [16],
      targetingCountries: [16],
      targetingGenders: [23],
      spendUsd: [23],
      clipEmbeddingUrl: [4],
      adTitle: [4],
      adDescription: [4],
      bundles: [4],
      advanced: [32],
      __typename: [4],
    },
    Prediction: {
      Id: [4],
      createdAt: [19],
      startedAt: [19],
      finishedAt: [19],
      status: [4],
      compromisedCredits: [10],
      endpoint: [4],
      input: [4],
      __typename: [4],
    },
    APIRequestSummary: {
      month: [10],
      year: [10],
      totalCalls: [10],
      imageSummary: [107],
      videoSummary: [108],
      __typename: [4],
    },
    ImageSummary: {
      totalCalls: [10],
      __typename: [4],
    },
    VideoSummary: {
      totalCalls: [10],
      durationRanges: [109],
      __typename: [4],
    },
    VideoDurationRange: {
      low: [10],
      medium: [10],
      high: [10],
      veryHigh: [10],
      __typename: [4],
    },
    APIDetailRequest: {
      startDate: [19],
      endDate: [19],
      __typename: [4],
    },
    ActiveInternalInvitationListElement: {
      id: [4],
      code: [4],
      isAdmin: [32],
      email: [4],
      expirationDate: [19],
      brands: [112],
      __typename: [4],
    },
    BrandPermissions: {
      brandId: [4],
      permissions: [41],
      __typename: [4],
    },
    UserWithPermissions: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      email: [4],
      name: [4],
      isAdmin: [32],
      businessAccount: [30],
      termsAndConditionsAccepted: [19],
      emailVerified: [32],
      isContractValid: [32],
      brandPermissions: [114],
      userPermissions: [23],
      config: [43],
      brandsConfig: [115],
      __typename: [4],
    },
    BrandPlainPermissions: {
      brandId: [4],
      permissions: [23],
      __typename: [4],
    },
    BrandConfig: {
      brandId: [4],
      config: [116],
      __typename: [4],
    },
    ConfigAttributes: {
      allowedChannels: [5],
      visibleRecommendationSources: [4],
      __typename: [4],
    },
    UserListElement: {
      id: [4],
      name: [4],
      email: [4],
      isAdmin: [32],
      lastLogin: [19],
      createdAt: [19],
      updatedAt: [19],
      brands: [112],
      __typename: [4],
    },
    ApiKey: {
      id: [4],
      apiKeyName: [4],
      endsWith: [4],
      __typename: [4],
    },
    Mutation: {
      createBrand: [
        120,
        {
          input: [121, "CreateBrandInput!"],
        },
      ],
      deleteBrand: [
        27,
        {
          brandId: [4, "String!"],
        },
      ],
      deleteBrandV2: [
        120,
        {
          id: [4, "String!"],
        },
      ],
      updateBrand: [
        120,
        {
          input: [122, "UpdateBrandInput!"],
          brandId: [4, "String!"],
        },
      ],
      updateBrandAssets: [
        32,
        {
          input: [126, "BrandAssetsInput!"],
          brandId: [4, "String!"],
        },
      ],
      requestLogoUploadData: [
        127,
        {
          input: [129, "UploadRequestInput!"],
        },
      ],
      setBusinessAccountPack: [
        43,
        {
          pack: [130, "PackInput!"],
        },
      ],
      setBusinessAccountCustomConfig: [
        43,
        {
          config: [131, "BusinessAccountCustomConfigInput!"],
        },
      ],
      createBusinessAccount: [
        30,
        {
          input: [134, "CreateBusinessAccountInput!"],
        },
      ],
      updateBusinessAccount: [
        30,
        {
          input: [135, "UpdateBusinessAccountInput!"],
        },
      ],
      updateBusinessAccountStatus: [
        30,
        {
          input: [136, "BusinessStatusInput!"],
        },
      ],
      cancelBusinessAccount: [30],
      createPlatformIntegration: [
        49,
        {
          redirectUri: [4],
          authCode: [4, "String!"],
          platform: [37, "Platform!"],
        },
      ],
      deletePlatformIntegration: [
        32,
        {
          platform: [37, "Platform!"],
        },
      ],
      requestUploadData: [
        127,
        {
          input: [129, "UploadRequestInput!"],
        },
      ],
      saveFeedbackRecommendation: [
        137,
        {
          input: [138, "RecommendationFeedbackInput!"],
        },
      ],
      createFolder: [
        99,
        {
          input: [143, "FolderInput!"],
        },
      ],
      saveCreativeInFolder: [
        92,
        {
          input: [144, "SaveCreativeInFolderInput!"],
        },
      ],
      deleteCreativesFromFolder: [
        32,
        {
          input: [145, "DeleteCreativesFromFolderInput!"],
        },
      ],
      createReferralInvitations: [
        146,
        {
          numberOfInvitations: [10, "Float!"],
        },
      ],
      createInternalInvitations: [
        146,
        {
          config: [148, "InvitationInput!"],
        },
      ],
      resendInvitation: [
        32,
        {
          invitationCode: [4, "String!"],
        },
      ],
      deleteInvitation: [
        32,
        {
          invitationCode: [4, "String!"],
        },
      ],
      setPaymentInfo: [
        32,
        {
          sessionId: [4, "String!"],
        },
      ],
      updateCreativeManualTags: [
        32,
        {
          input: [151, "CreativeManualTagInput!"],
        },
      ],
      createCustomTags: [
        32,
        {
          input: [153, "CreateCustomTagInput!"],
        },
      ],
      logIn: [
        154,
        {
          input: [155, "AuthenticationInput!"],
        },
      ],
      impersonateLogin: [
        154,
        {
          input: [156, "ImpersonationInput!"],
        },
      ],
      regeneratePassword: [
        32,
        {
          input: [157, "ForgetPasswordInput!"],
        },
      ],
      refreshLogInToken: [4],
      updateUserProfile: [
        31,
        {
          input: [158, "UserProfileInput!"],
        },
      ],
      updateUserPermissions: [
        32,
        {
          input: [159, "UserPermissionsInput!"],
        },
      ],
      signUp: [
        31,
        {
          code: [4],
          user: [160, "SignUpInput!"],
        },
      ],
      deleteUser: [
        32,
        {
          userId: [4, "String!"],
        },
      ],
      logout: [32],
      deleteApiKey: [
        32,
        {
          apiKeyId: [23, "Int!"],
        },
      ],
      createApiKey: [
        4,
        {
          apiKeyName: [4, "String!"],
        },
      ],
      __typename: [4],
    },
    BrandV2: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      name: [4],
      logoUrl: [4],
      sector: [28],
      country: [4],
      adAccounts: [4],
      socialAccounts: [4],
      status: [29],
      createdBy: [4],
      extraAttributes: [34],
      __typename: [4],
    },
    CreateBrandInput: {
      name: [4],
      logoUrl: [4],
      sector: [28],
      country: [16],
      __typename: [4],
    },
    UpdateBrandInput: {
      name: [4],
      logoUrl: [4],
      sector: [28],
      country: [16],
      extraAttributes: [123],
      status: [29],
      adAccounts: [124],
      socialAccounts: [125],
      __typename: [4],
    },
    BrandExtraAttributeInput: {
      names: [4],
      communicationIdeas: [4],
      visualElements: [4],
      overlayTextTaglines: [4],
      speechTaglines: [4],
      mustWinAttributes: [4],
      __typename: [4],
    },
    AdAccountInput: {
      id: [23],
      type: [38],
      __typename: [4],
    },
    SocialAccountInput: {
      id: [23],
      type: [40],
      __typename: [4],
    },
    BrandAssetsInput: {
      adAccount: [124],
      socialAccount: [125],
      __typename: [4],
    },
    UploadDataResponse: {
      id: [4],
      originalUrl: [4],
      presignedData: [128],
      __typename: [4],
    },
    PresignedData: {
      url: [4],
      fields: [4],
      __typename: [4],
    },
    UploadRequestInput: {
      mimeType: [4],
      extension: [4],
      creativeType: [94],
      __typename: [4],
    },
    PackInput: {
      businessAccountId: [4],
      name: [4],
      __typename: [4],
    },
    BusinessAccountCustomConfigInput: {
      businessAccountId: [4],
      config: [132],
      brandConfig: [133],
      __typename: [4],
    },
    BusinessAccountConfigInput: {
      pack: [4],
      allowedChannels: [5],
      purchasableChannels: [5],
      allowedCreativeAnalyticsChannels: [5],
      allowedCreativeLabChannels: [5],
      purchasableCreativeAnalyticsChannels: [5],
      purchasableCreativeLabChannels: [5],
      visibleRecommendationSources: [4],
      visibleFeatures: [44],
      visibleCognitiveMetrics: [45],
      visibleTagMetrics: [12],
      visibleAdMetrics: [12],
      visibleCreativeAnalyticsCampaignObjectives: [46],
      visibleCreativeLabCampaignObjectives: [46],
      __typename: [4],
    },
    ConfigAttributesInput: {
      allowedChannels: [5],
      visibleRecommendationSources: [4],
      __typename: [4],
    },
    CreateBusinessAccountInput: {
      businessName: [4],
      __typename: [4],
    },
    UpdateBusinessAccountInput: {
      businessName: [4],
      address: [4],
      businessPhone: [4],
      website: [4],
      businessLogoUrl: [4],
      __typename: [4],
    },
    BusinessStatusInput: {
      businessAccountId: [4],
      status: [33],
      __typename: [4],
    },
    RecommendationFeedback: {
      creativeId: [4],
      userId: [4],
      action: [4],
      recommendationPayload: [58],
      __typename: [4],
    },
    RecommendationFeedbackInput: {
      creativeId: [4],
      action: [139],
      recommendationPayload: [140],
      __typename: [4],
    },
    RecommendationFeedbackAction: {},
    InputRecommendation: {
      ruleId: [23],
      goal: [4],
      source: [4],
      specificSource: [4],
      type: [59],
      campaignObjective: [46],
      action: [60],
      title: [4],
      customMessage: [4],
      targetTags: [141],
      sourceTags: [141],
      metricImpacts: [142],
      channel: [5],
      impactedCognitive: [45],
      __typename: [4],
    },
    InputTag: {
      tagSuperClass: [4],
      tagClass: [4],
      tagValue: [4],
      importance: [10],
      __typename: [4],
    },
    MetricImpactInput: {
      metric: [62],
      impact: [10],
      trend: [63],
      impactScore: [64],
      __typename: [4],
    },
    FolderInput: {
      name: [4],
      brandId: [4],
      parentId: [10],
      __typename: [4],
    },
    SaveCreativeInFolderInput: {
      creativeId: [4],
      folderId: [10],
      name: [4],
      __typename: [4],
    },
    DeleteCreativesFromFolderInput: {
      creativeIds: [4],
      folderId: [10],
      __typename: [4],
    },
    Invitation: {
      id: [3],
      createdAt: [19],
      updatedAt: [19],
      code: [4],
      email: [4],
      type: [147],
      isAdmin: [32],
      expirationDate: [19],
      __typename: [4],
    },
    InvitationType: {},
    InvitationInput: {
      email: [4],
      isAdmin: [32],
      numberOfInvitations: [23],
      brands: [149],
      __typename: [4],
    },
    BrandPermissionsInput: {
      brandId: [4],
      permissions: [150],
      __typename: [4],
    },
    PermissionGroupInput: {
      id: [23],
      name: [4],
      __typename: [4],
    },
    CreativeManualTagInput: {
      brandId: [4],
      creativeId: [4],
      tagsToAdd: [152],
      tagsToDelete: [152],
      __typename: [4],
    },
    CreateTagInput: {
      tagSuperClass: [4],
      tagClass: [4],
      tagValue: [4],
      importance: [10],
      __typename: [4],
    },
    CreateCustomTagInput: {
      brandId: [4],
      tags: [152],
      __typename: [4],
    },
    LoggedInUser: {
      sessionToken: [4],
      __typename: [4],
    },
    AuthenticationInput: {
      email: [4],
      password: [4],
      __typename: [4],
    },
    ImpersonationInput: {
      email: [4],
      password: [4],
      __typename: [4],
    },
    ForgetPasswordInput: {
      email: [4],
      __typename: [4],
    },
    UserProfileInput: {
      name: [4],
      currentPassword: [4],
      newPassword: [4],
      termsAndConditionsAccepted: [32],
      __typename: [4],
    },
    UserPermissionsInput: {
      userId: [4],
      brands: [149],
      isAdmin: [32],
      __typename: [4],
    },
    SignUpInput: {
      name: [4],
      email: [4],
      password: [4],
      termsAndConditionsAccepted: [32],
      source: [161],
      __typename: [4],
    },
    UserCreationSource: {},
  },
};
