// import { lazy } from "react";
import { Route } from "./features/navigation/models/route.model";
import ExternalApiDashboardPage from "./pages/external-api-dashboard/external-api-dashboard.page";
import { ExternalApiDashboardRoutes } from "./pages/external-api-dashboard/external-api-dashboard.routes";
import { MyProfileRoutes } from "./pages/my-profile/my-profile.routes";

// const SignUpPage = lazy(() => import("src/app/pages/signup/signup.page"));

export const AppRoutes: Route[] = [
  {
    path: "/",
    element: ExternalApiDashboardPage,
    children: [...ExternalApiDashboardRoutes, ...MyProfileRoutes],
    title: "External API Dashboard",
  },
  // {
  //   path: "signup",
  //   title: "Signup",
  //   element: SignUpPage,
  // },
  // {
  //   path: "signup/:token",
  //   title: "Signup",
  //   element: SignUpPage,
  // },
];
