import { AssetsRepository } from "src/domain/assets/assets.domain";
import { BrandAssetsInput, IntegrationsAssets } from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class AssetsBackendRepository implements AssetsRepository {
  async updateBrandAssets(args: { assets: BrandAssetsInput; brandId: string }) {
    return client.chain.mutation
      .updateBrandAssets({
        input: args.assets,
        brandId: args.brandId,
      })
      .get() as Promise<boolean>;
  }

  async getBrandAssets(brandId: string) {
    return client.chain.query.getBrandAssets({ brandId }).get({
      adAccounts: {
        id: 1,
        originalId: 1,
        name: 1,
        type: 1,
        platform: 1,
      },
      socialAccounts: {
        id: 1,
        name: 1,
        originalId: 1,
        type: 1,
        platform: 1,
      },
    }) as Promise<IntegrationsAssets>;
  }

  async getBusinessAccountAssets() {
    return client.chain.query.getBusinessAccountAssets.get({
      adAccounts: {
        id: 1,
        name: 1,
        type: 1,
        platform: 1,
      },
      socialAccounts: {
        id: 1,
        name: 1,
        type: 1,
        platform: 1,
      },
    }) as Promise<IntegrationsAssets>;
  }
}
