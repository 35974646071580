import { FC } from "react";
import AnimationUI from "./animation.ui";
import loadingAnimation from "./three-dots.animation.json";

export const LoadingAnimationUI: FC = () => {
  return (
    <AnimationUI
      isLoop={true}
      style={{
        width: "30px",
        height: "40px",
        translate: "0px -3px",
      }}
      animation={loadingAnimation}
    />
  );
};
